<style lang="less" scoped></style>

<template>
  <el-form ref="editForm" :rules="rules" :model="info" label-width="100px">
    <el-form-item label="父群组" prop="parentId">
      <el-select
        v-model="info.parentId"
        placeholder="请选择"
        filterable
        remote
        clearable
        :disabled="user.role === 'bigScrean' || isEdit"
        style="width: 100%;"
        :remote-method="queryList"
      >
        <el-option
          v-for="item in groupList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="名 称" prop="name">
      <el-input v-model="info.name" placeholder="请输入群组名称"></el-input>
    </el-form-item>

    <el-form-item label="账 号" prop="username" v-if="!this.isEdit">
      <el-input
        v-model="info.username"
        placeholder="请输入群用户账号"
      ></el-input>
    </el-form-item>

    <el-form-item label="密 码" prop="password" v-if="!this.isEdit">
      <el-input
        v-model="info.password"
        type="password"
        placeholder="请输入群组用户密码"
      ></el-input>
    </el-form-item>

    <el-form-item label="电 话" prop="mobile">
      <el-input
        v-model="info.mobile"
        :maxlength="11"
        placeholder="请输入联系电话"
      ></el-input>
    </el-form-item>

    <el-form-item class="text-right m-t-md">
      <el-button type="primary" size="large" @click="submit">保存</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
  import { mapGetters } from "vuex";
  import {
    updateGroups,
    addGroups,
    getParentGroups,
  } from "../../services/group";
  import { getBigUser } from "../../services/user";
  import {
    valiPhone,
    valiPassword,
    valiAccount,
  } from "../../utils/validator/element";
  export default {
    data() {
      return {
        groupList: [],
        userList: [],
        info: {
          parentId: "",
          name: "",
          username: "",
          password: "",
          mobile: "",
        },
        rules: {
          // parentId: [{ required: true, message: "请选择", trigger: "change" }],
          name: [{ required: true, message: "请输入", trigger: "blur" }],
          mobile: [
            { required: true, message: "请输入", trigger: "blur" },
            { validator: valiPhone, trigger: "blur" },
          ],
          username: [
            { required: true, message: "请输入", trigger: "blur" },
            { validator: valiAccount, trigger: "blur" },
          ],
          password: [
            { required: true, message: "请输入", trigger: "blur" },
            { validator: valiPassword, trigger: "change" },
          ],
        },
      };
    },
    computed: {
      ...mapGetters({ user: "userInfo" }),
    },
    props: {
      isEdit: {
        type: Boolean,
        default: false,
      },
      detailVisible: {
        type: Boolean,
        default: false,
      },
      propInfo: {
        type: Object,
        default() {
          return {
            parentId: "",
            name: "",
            userId: "",
            conectUser: "",
            mobile: "",
          };
        },
      },
    },
    methods: {
      submit() {
        this.$refs.editForm.validate((valid) => {
          if (valid) {
            this.update();
          }
        });
      },
      async update() {
        let responseData = null;
        if (this.isEdit) {
          responseData = await updateGroups(this.info);
        } else {
          responseData = await addGroups(this.info);
        }
        if (responseData.errorCode === 0) {
          this.$emit("onSubmit", responseData.data);
          this.$notify.success({
            title: "成功",
            message: this.isEdit ? "修改成功" : "新增成功",
          });
        }
      },

      async queryList(keys) {
        const responseData = await getParentGroups(
          { keys },
          { size: 9999, page: 1 }
        );
        if (responseData && responseData.data && responseData.errorCode === 0) {
          this.groupList = responseData.data.datas || [];
        }
      },

      async queryUsers(name) {
        const userNameData = await getBigUser({ name });
        if (userNameData && userNameData.data && userNameData.errorCode === 0) {
          this.userList = userNameData.data.datas || [];
        }
      },

      initData() {
        if (this.isEdit) {
          this.info = Object.assign({}, this.propInfo);
        } else {
          if (
            this.user.role === "bigScrean" &&
            this.user.groupManageLevel == 1
          ) {
            this.info.parentId = this.user.groupManageId;
          }
        }
      },
    },
    created() {
      this.queryList();
      this.queryUsers();
      this.initData();
    },
    watch: {
      detailVisible(newval) {
        if (newval) {
          this.initData();
          this.queryUsers();
        } else {
          this.info = {
            parentId: "",
            name: "",
            userId: "",
            conectUser: "",
            mobile: "",
          };
        }
      },
    },
  };
</script>
