<style lang="less" scoped>
  .content {
    margin-bottom: 30px !important;
    .list-content {
      margin-bottom: 50px;
      padding: 30px;
      .toolbar {
        padding-top: 20px;
      }
    }
  }
  #setDeviceShow .el-checkbox {
    margin: 10px 15px 15px 0;
  }
  #setDeviceShow .el-checkbox + .el-checkbox {
    margin: 10px 15px 15px 0;
  }
</style>
<template>
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: this.$route.path }">
        分组列表
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="page-container clearfix" v-on:keyup.13="handleSearch">
      <!--查询筛选-->
      <el-col :span="24" style="padding-bottom: 0px;">
        <el-form :inline="true" :model="filters">
          <el-form-item>
            <el-input
              style="width: 240px;"
              v-model="filters.key"
              clearable
              placeholder="群组名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-form>
      </el-col>

      <el-table
        :data="dataList"
        class="list-table"
        ref="editTable"
        row-key="id"
        border
        lazy
        :load="loadChild"
        :tree-props="{ children: 'children', hasChildren: 'children' }"
      >
        <!-- <el-table-column type="selection" width="35"> </el-table-column> -->

        <el-table-column align="center" label="序号" width="80">
          <template slot-scope="scope">
            <span>{{ scope.$index + pager.size * (pager.page - 1) + 1 }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="name" label="群组名"></el-table-column>

        <el-table-column prop="username" label="账号"></el-table-column>

        <el-table-column prop="parentName" label="父群组"></el-table-column>

        <el-table-column prop="mobile" label="联系电话"></el-table-column>

        <el-table-column prop="deviceCount" label="设备数量"></el-table-column>

        <el-table-column
          :show-overflow-tooltip="true"
          width="170"
          label="创建时间"
        >
          <template slot-scope="scope">
            {{ scope.row.createTime | formatTime }}
          </template>
        </el-table-column>

        <el-table-column label="操作" width="200" v-if="showBtn()">
          <template slot-scope="scope">
            <el-button
              size="small"
              @click="handleEdit(scope.$index, scope.row)"
            >
              修改
            </el-button>
            <el-button
              type="warning"
              size="small"
              @click="handleResetPwd(scope.$index, scope.row)"
            >
              重置
            </el-button>

            <el-button
              type="danger"
              class="m-l-xs"
              size="small"
              :disabled="user.id === scope.row.userId"
              @click="handleDel(scope.$index, scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-col :span="24" class="bottom-tool-container">
        <el-button
          v-if="showBtn()"
          type="primary"
          @click="detailVisible = true"
        >
          新建
        </el-button>

        <!--分页-->
        <el-pagination
          @size-change="pageSizeChange"
          @current-change="pageCurrentChange"
          :current-page="pager.page"
          :page-sizes="[PAGE_SIZE, PAGE_SIZE * 2, PAGE_SIZE * 3, 100]"
          :page-size="pager.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          class="pull-right"
        ></el-pagination>
      </el-col>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :title="isEdit ? '修改' : '新增'"
      :visible.sync="detailVisible"
    >
      <detail
        :propInfo="choosedRow"
        :detailVisible="detailVisible"
        :isEdit="isEdit"
        v-on:onSubmit="onSubmit"
      ></detail>
    </el-dialog>
  </div>
</template>
<script>
  import { mapGetters } from "vuex";
  import { PAGE_SIZE } from "../../config";
  import { Notification } from "element-ui";
  import { getGroups, getParentGroups, delGroups } from "../../services/group";
  import detail from "./detail.vue";
  import { resetUserPwd } from "../../services/user";
  import moment from "moment";

  export default {
    data() {
      return {
        isShow: false,
        editPermission: true,
        isEdit: false,
        filterBigScreenList: [],
        bigScreenList: [],
        dataList: [],
        maps: new Map(),
        filters: {
          key: "",
        },
        PAGE_SIZE,
        pager: {
          page: 1,
          size: PAGE_SIZE,
        },
        total: 0,
        listLoading: false,
        detailVisible: false, //新增
        deviceDialogVisible: false, //设备显示情况
        choosedRowIndex: 0,
        setShow: {},
        choosedRow: {},
        sels: [], //列表选中列
        permissionIds: [],
        userAllDeviceList: [], //查询该用户对应的所有设备
        userToShowDdeviceList: [], //查询该用户已经设置要显示的设备
        deviceIds: [], //查询该用户的设备id数组
        userId: "",
        userInfos: {},
        wechatUsers: [], //微信用户
        updataInfo: {
          //用来专门存放修改后的数据，作为数据存传回后台做修改
          id: "", //
          userId: "", //
          userName: "", //客户名称
          title: "", // 空气道名称
          logoUrl: "", //logo图片
          imageUrl: "", // 横屏图片
          verticalImageUrl: "", // 竖屏图片
          pollingTime: "", // 轮播时间
        },
      };
    },
    computed: {
      ...mapGetters({ user: "userInfo" }),
    },
    components: {
      // 'update-view': updateBigScreenView,
      detail,
    },
    filters: {
      formatTime(row) {
        return row ? moment(row).format("YYYY-MM-DD HH:mm:ss") : "";
      },
    },
    methods: {
      async loadChild(tree, treeNode, resolve) {
        // const list = await this.querySubGroups(tree.id) || []
        // console.log(tree);
        // console.log(treeNode);

        const params = { parentId: tree.id };
        const res = await getGroups(params, { size: 9999, page: 1 });
        if (res.errorCode === 0 && res.data) {
          const list = res.data.datas || [];
          this.maps.set(tree.id, { tree, treeNode, resolve });
          console.log(list);
          resolve(list);
        }
      },

      showBtn() {
        return (
          this.user.role === "admin" ||
          (this.user.role === "bigScrean" && this.user.groupManageLevel == 1)
        );
      },
      handleSearch() {
        this.pager.page = 1;
        this.queryList();
      },

      /**
       * 单条数据的删除
       */
      handleDel: function(index, row) {
        this.$confirm("确认删除该记录吗?", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            delGroups(row.id).then((res) => {
              this.listLoading = false;
              if (res.errorCode === 0) {
                Notification.success({
                  title: "成功",
                  message: "删除成功",
                });
                const { tree, treeNode, resolve } =
                  this.maps.get(row.parentId) || {};
                if (tree && tree.id) {
                  console.log(this.$refs.editTable);

                  this.$set(
                    this.$refs.editTable.store.states.lazyTreeNodeMap,
                    row.parentId,
                    []
                  );
                  this.loadChild(tree, treeNode, resolve);
                }

                this.queryList();
              }
            });
          })
          .catch(() => {});
      },

      /**
       * checkbox勾选的变动的回调
       */
      selsChange: function(sels) {
        this.sels = sels;
      },

      /**
       * 设置分页的size
       */
      pageSizeChange(val) {
        //    	 console.log(`每页 ${val} 条`);
        this.pager.size = val;
        this.pager.page = 1;
        this.queryList();
      },
      /**
       *  翻页
       */
      pageCurrentChange(page) {
        if (this.loaded) {
          this.pager.page = page;
          window.router.replace({
            path: this.$route.path,
            query: { page: page },
          });
          this.queryList();
        }
      },

      /**
       * 重置密码
       */
      handleResetPwd(index, row) {
        this.$confirm("确认重置该分组用户的密码吗?", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            //NProgress.start();
            resetUserPwd(row.userId, "123456").then((res) => {
              this.listLoading = false;
              if (res.errorCode === 0 && res.data && res.data.result === true) {
                Notification.success({
                  title: "成功",
                  message: "重置密码成功",
                });
              }
            });
          })
          .catch(() => {});
      },

      // 子群组
      async querySubGroups(parentId) {
        const params = { parentId };
        const res = await getGroups(params, { size: 9999, page: 1 });
        if (res.errorCode === 0) {
          return res.data.datas;
        }
      },

      // 列表
      async queryList() {
        this.listLoading = true;

        const responseData = await getParentGroups(this.filters, this.pager);
        if (responseData && responseData.data && responseData.errorCode === 0) {
          const dataList = responseData.data.datas || [];
          this.dataList = dataList.map((item) => {
            if (item.level === 1) {
              item.children = [];
            }
            return item;
          });
          this.total = responseData.data.totalCount;
        }
        this.loaded = true;
        this.listLoading = false;
      },

      /**
       *用户修改或者新增成功
       * @param isCreate true 创建  false 修改
       */
      onSubmit(roleData) {
        this.detailVisible = false;
        if (roleData.parentId) {
          const { tree, treeNode, resolve } =
            this.maps.get(roleData.parentId) || {};
          if (tree && tree.id) {
            this.loadChild(tree, treeNode, resolve);
          }
        }
        this.queryList();
      },
      /**
       * 点击按钮跳转到配置编辑
       */
      async handleEdit(index, row) {
        this.isEdit = true;
        this.choosedRowIndex = index;
        this.choosedRow = Object.assign({}, row);
        this.detailVisible = true;
      },
    },
    created() {
      if (this.$route.query.page) {
        this.pager.page = +this.$route.query.page;
      }

      this.queryList();
    },

    watch: {
      detailVisible(newVal) {
        if (newVal === false) {
          this.isEdit = false;
        }
      },
    },
  };
</script>
